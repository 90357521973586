<template>
  <b-row>
    <div class="col-12 col-sm-9">
      <b-card class="mb-3" :header="heading" no-body>
        <div class="card-body">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Name:">
                <b-form-input
                  id="form-input-name"
                  name="form-input-name"
                  v-model="itemForm.name"
                  v-validate="{ required: false, min: 1 }"
                  :state="validateState('form-input-name')"
                  data-vv-as="To name"
                  />
                <b-form-invalid-feedback
                  id="form-input-toEnamemail-feedback"
                >
                  {{ veeErrors.first('form-input-name') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Storefront:">
                <b-v-select
                  id="form-input-projectId"
                  name="form-input-projectId"
                  v-validate="{ required: true }"
                  :state="validateState('form-input-projectId')"
                  v-model="itemForm.projectId"
                  :searchable="true"
                  :options="projectsList"
                />
                <b-form-invalid-feedback id="form-input-projectId-feedback">
                  {{ veeErrors.first('form-input-projectId') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Enabled:">
                <b-v-select
                  id="form-input-enabled"
                  name="form-input-enabled"
                  v-model="itemForm.enabled"
                  v-validate="{ required: false }"
                  :state="validateState('form-input-enabled')"
                  :searchable="true"
                  :options="statusList"
                />
                <b-form-invalid-feedback id="form-input-enabled-feedback">
                  {{ veeErrors.first('form-input-enabled') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="On Event:" description="Please note that some events are not available for certain warehouses, like SHIPMENT_RECEIVED.">
                <b-v-select
                  id="form-input-onEvent"
                  name="form-input-onEvent"
                  v-validate="{ required: true }"
                  v-model="itemForm.onEvent"
                  :state="validateState('form-input-onEvent')"
                  :searchable="true"
                  :options="onEventList"
                />
                <b-form-invalid-feedback
                  id="form-input-onEvent-feedback"
                >
                  {{ veeErrors.first('form-input-onEvent') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Type:">
                <b-v-select
                  id="form-input-type"
                  name="form-input-type"
                  v-model="itemForm.type"
                  v-validate="{ required: false }"
                  :state="validateState('form-input-type')"
                  :searchable="true"
                  :options="typesList"
                />
                <b-form-invalid-feedback id="form-input-type-feedback">
                  {{ veeErrors.first('form-input-type') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" v-if="itemForm.projectId && itemForm.type != 'Webhook'">
              <b-form-group label="Content Template:">
                <b-v-select
                  id="form-input-contentTemplateId"
                  name="form-input-contentTemplateId"
                  v-validate="{ required: true }"
                  :state="validateState('form-input-contentTemplateId')"
                  v-model="itemForm.contentTemplateId"
                  :searchable="true"
                  :options="contentTemplateList"
                />
                <b-form-invalid-feedback id="form-input-contentTemplateId-feedback">
                  {{ veeErrors.first('form-input-contentTemplateId') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" v-if="itemForm.type == 'Email'">
              <b-form-group label="Send to email sourced from:" description="Important: If the email and/or phone number isn't available, the automation won't be executed at all for that instance.">
                <b-v-select
                  id="form-input-fromEvent"
                  name="form-input-fromEvent"
                  v-model="itemForm.fromEvent"
                  v-validate="{ required: false }"
                  :state="validateState('form-input-fromEvent')"
                  :searchable="true"
                  :options="fromEventList"
                />
                <b-form-invalid-feedback id="form-input-fromEvent-feedback">
                  {{ veeErrors.first('form-input-fromEvent') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" v-if="itemForm.type == 'Webhook'">
              <b-form-group label="Webhook URL:" description="Requests are sent via a POST request.">
                <b-form-input
                  id="form-input-toURL"
                  name="form-input-toURL"
                  v-model="itemForm.toURL"
                  v-validate="{ required: false, url: true }"
                  :state="validateState('form-input-toUrl')"
                  data-vv-as="Webhook URL"
                  />
                <b-form-invalid-feedback
                  id="form-input-toURL-feedback"
                >
                  {{ veeErrors.first('form-input-toURL') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" >
              <b-form-group label="To SMS:" v-if="!itemForm.fromEvent && itemForm.type == 'SMS'">
                <b-form-input
                  id="form-input-toSMS"
                  name="form-input-toSMS"
                  v-model="itemForm.toSMS"
                  v-validate="{ required: false, min: 3 }"
                  :state="validateState('form-input-toSMS')"
                  data-vv-as="To SMS"
                  />
                <b-form-invalid-feedback
                  id="form-input-toSMS-feedback"
                >
                  {{ veeErrors.first('form-input-toSMS') }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="To Email:" v-else-if="!itemForm.fromEvent && itemForm.type == 'Email'">
                <input class="form-control"
                  id="form-input-toEmail"
                  name="form-input-toEmail"
                  v-model="itemForm.toEmail"
                  v-validate="{ required: false, email: true }"
                  :state="validateState('form-input-toEmail')"
                  data-vv-as="To Email"
                  />
                <b-form-invalid-feedback
                  id="form-input-toEmail-feedback"
                >
                  {{ veeErrors.first('form-input-toEmail') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
    <aside class="col-12 col-sm-3">
      <b-card header="Actions">
        <b-button
          :disabled="savingItem"
          variant="primary"
          block
          type="submit"
          @click="SaveForm"
        >
          <span v-if="savingItem">Saving...</span>
          <span v-else>Save Changes</span>
        </b-button>
        <router-link
          :disabled="savingItem"
          class="mt-2 btn btn-block btn-outline-danger"
          :to="{ name: 'Integrations.ShipCodeMappings.Create.Index' }"
        >
          <span>Cancel</span>
        </router-link>
      </b-card>
    </aside>
  </b-row>
</template>

<script>
import { mapState } from 'vuex';
import { mapStoreGetSet } from '@/helpers/utils/storeHelpers.js';
import { crudMixinGen, myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
export default {
  mixins: [crudMixinGen('automation'), myCommonDataMixin],
  props: {
    id: {
      type: String,
      default: '',
      required: false
    }
  },

  data: () => ({
    heading: 'Automation Form',
    contentTemplateAll: [],
    onEventList: [
      {
        value: 'ORDER_CREATED',
        text: 'When Order is Created'
      },
      {
        value: 'ORDER_UPDATED',
        text: 'When Order is Updated'
      },
      {
        value: 'ORDER_FULFILLED',
        text: 'When Order is Fulfilled'
      },
      {
        value: 'ORDER_SHIPPED',
        text: 'When Order is Shipped (triggers once per order)'
      },
      {
        value: 'ORDER_CANCELLED',
        text: 'When Order is Cancelled'
      },
      {
        value: 'ORDER_SPLIT',
        text: 'When Order is Split'
      },
      {
        value: 'ORDER_RESHIPPED',
        text: 'When Order is Reshipped'
      },
      {
        value: 'SHIPMENT_CREATED',
        text: 'When Shipment is created (can trigger multiple times for one order shipped)'
      },
      {
        value: 'SHIPMENT_UPDATED',
        text: 'When Shipment is updated'
      },
      {
        value: 'SHIPMENT_RECEIVED',
        text: 'When Shipment is received'
      },
      {
        value: 'SHIPMENT_CANCELLED',
        text: 'When Shipment is cancelled'
      },
      {
        value: 'BULK_SHIPMENT_CREATED',
        text: 'When Bulk Shipment is Created'
      },
      {
        value: 'BULK_SHIPMENT_SHIPPED',
        text: 'When Bulk Shipment is Shipped'
      },
      {
        value: 'BULK_SHIPMENT_UPDATED',
        text: 'When Bulk Shipment is Updated'
      },
      {
        value: 'WARESHIPMENT_CREATED',
        text: 'When Warehouse Shipment is Created'
      },
      {
        value: 'WARESHIPMENT_UPDATED',
        text: 'When Warehouse Shipment is Updated'
      },
      {
        value: 'WARESHIPMENT_SHIPPED',
        text: 'When Warehouse Shipment is Shipped (In Transit)'
      },
      {
        value: 'WARESHIPMENT_RECEIVED',
        text: 'When Warehouse Shipment is Received'
      },
      {
        value: 'ORDERRETURN_CREATED',
        text: 'When an Order Return is created'
      },
      {
        value: 'ORDERRETURN_PROCESSED',
        text: 'When an Order Return is processed into the warehouse'
      },
    ],
    typesList: [
      {
        value: 'Email',
        text: 'Email'
      },
      {
        value: 'Webhook',
        text: 'Webhook'
      }
    ],
    fromEventList: [
      {
        value: true,
        text: 'The Order/Shipment event'
      },
      {
        value: false,
        text: 'Enter manually'
      }
    ],
    statusList: [
      {
        value: true,
        text: 'Enabled'
      },
      {
        value: false,
        text: 'Disabled'
      }
    ]
  }),

  computed: {
    contentTemplateList: {
      get() {
        let itmsList = [];
        let myItems = this.contentTemplateAll;
        myItems.forEach(itm => {
          itmsList.push({ value: itm.id, text: itm.name});
        });
        return itmsList;
      },
      set(val) {
      }
    }
  },

  mounted() {
    if (this.id) {
      this.ResetForm();
      this.LoadItem(this.id);
    } else {
      this.ResetForm();
    }
  },

  watch: {
    'itemForm.projectId': function(newVal) {
      this.LoadTemplatesFor(newVal);
    },
    'itemForm.type': function(newVal) {
      this.LoadTemplatesFor(this.itemForm.projectId);
    },
  },

  methods: {
    LoadTemplatesFor(projectId) {
      this.contentTemplateAll = [];

      if (projectId) {
        this.$http
          .post('/api/automations/contentTemplates/byProject/' + projectId)
          .then(resp => {
            let possibl = resp.data;
            this.contentTemplateAll = possibl.filter(a => a.type == this.itemForm.type);
            if(this.contentTemplateAll.find(a => a.id == this.itemForm.contentTemplateId) == null) {
              this.itemForm.contentTemplateId = null;
            }
          })
          .catch(err => {
            this.storesListAll = [];
          });
      }
    },
    SaveForm() {
      this.savingItem = true;
      this.SaveItem()
        .then(resp => {
          this.ResetForm();
          this.$swal({ title: 'Item Was Saved!', icon: 'success' });
          this.$router.push({
            name: 'Other.Automation.Index'
          });
        })
        .catch(err => {
          this.savingItem = false;
          if (err.response && err.response.data && err.response.data.errorMessage) {
            this.$swal({ title: err.response.data.errorMessage, icon: 'error' });
          } else {
            this.$swal({ title: 'Item Was NOT Saved!', icon: 'error' });
          }
        });
    }
  }
};
</script>
