<template>
  <b-row>
    <div class="col-12 col-sm-9">
      <b-card class="mb-3" :header="heading" no-body>
        <form @submit.prevent class="card-body" data-vv-scope="itemForm">
          <div class="row" v-if="itemForm">
            <div class="col-6 col-md-6" v-if="itemForm.status">
              <b-form-group label="Status:">
                <b-form-input v-model="itemForm.status" :disabled="true" />
              </b-form-group>
            </div>
            <div class="col-6 col-md-6" v-if="itemForm.createdAt">
              <b-form-group label="Created At:">
                <b-form-input
                  v-model="itemForm.createdAt"
                  type="date"
                  :disabled="true"
                />
              </b-form-group>
            </div>
            <div class="col-6 col-md-6" v-if="itemForm.order">
              <b-form-group label="Order Number:">
                <b-form-input
                  v-model="itemForm.order.orderNumber"
                  :disabled="true"
                />
              </b-form-group>
            </div>
            <div class="col-6 col-md-6">
              <b-form-group label="Returned Date:">
                <b-form-input
                  :disabled="true"
                  v-validate="{ required: true }"
                  id="form-input-returnDate"
                  name="form-input-returnDate"
                  :state="
                    validateStateScoped('itemForm', 'form-input-returnDate')
                  "
                  v-model="itemForm.receiveDate"
                  type="date"
                />
                <b-form-invalid-feedback id="form-input-returnDate-feedback">
                  {{ veeErrors.first('form-input-returnDate') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>

            <div class="col-6 col-md-6" v-if="itemForm.originAddress">
              <b-form-group label="Ship From Name:">
                <b-form-input
                  v-model="itemForm.originAddress.name"
                  :disabled="true"
                />
              </b-form-group>
            </div>
            <div class="col-6 col-md-6" v-if="itemForm.originAddress">
              <b-form-group label="Ship From Street 1:">
                <b-form-input
                  v-model="itemForm.originAddress.street1"
                  :disabled="true"
                />
              </b-form-group>
            </div>
            <div class="col-6 col-md-6" v-if="itemForm.originAddress">
              <b-form-group label="Ship From City:">
                <b-form-input
                  v-model="itemForm.originAddress.city"
                  :disabled="true"
                />
              </b-form-group>
            </div>
            <div class="col-6 col-md-6" v-if="itemForm.originAddress">
              <b-form-group label="Ship From Street 2:">
                <b-form-input
                  v-model="itemForm.originAddress.street2"
                  :disabled="true"
                />
              </b-form-group>
            </div>
            <div class="col-6 col-md-6" v-if="itemForm.originAddress">
              <b-form-group label="Ship From State:">
                <b-form-input
                  v-model="itemForm.originAddress.state"
                  :disabled="true"
                />
              </b-form-group>
            </div>
            <div class="col-6 col-md-6" v-if="itemForm.originAddress">
              <b-form-group label="Ship From Street 3:">
                <b-form-input
                  v-model="itemForm.originAddress.street3"
                  :disabled="true"
                />
              </b-form-group>
            </div>
            <div class="col-6 col-md-6" v-if="itemForm.originAddress">
              <b-form-group label="Phone:">
                <b-form-input
                  v-model="itemForm.originAddress.phone"
                  :disabled="true"
                />
              </b-form-group>
            </div>
            <div class="col-6 col-md-6" v-if="itemForm.originAddress">
              <b-form-group label="Postcode:">
                <b-form-input
                  v-model="itemForm.originAddress.postalCode"
                  :disabled="true"
                />
              </b-form-group>
            </div>

            <b-col cols="12">
              <b-form-group label="Notes">
                <b-form-textarea v-model="itemForm.notes" :disabled="true" />
              </b-form-group>
            </b-col>
          </div>
        </form>
      </b-card>
      <b-card no-body v-if="itemForm">
        <template #header class="card-header">
          <h4 class="card-header-title">Items Returned</h4>
        </template>
        <b-table
          class="m-0"
          v-if="itemForm.items && itemForm.items.length > 0"
          :striped="true"
          :bordered="true"
          :outlined="true"
          :small="false"
          :hover="false"
          :dark="false"
          :responsive="true"
          :items="itemForm.items"
          head-variant="dark"
          :fields="fieldsItems"
        >
          <template v-slot:cell(actions)="data">
            <div class="d-flex">
              <button
                class="btn btn-danger"
                @click="data.item.id ? RemoveItem(data.item) : ''"
              >
                Remove
              </button>
            </div>
          </template>

          <template v-slot:cell(rma)="data">
            {{ data.item.rma }}
          </template>

          <template v-slot:cell(quantityReceived)="data">
            {{ data.item.quantityReceived }}
          </template>

          <template v-slot:cell(quantityToReturn)="data">
            {{ data.item.quantityToReturn }}
          </template>
          <template v-slot:cell(quantityDamaged)="data">
            {{ data.item.quantityDamaged }}
          </template>

          <template v-slot:cell(sku)="data">
            <div>{{ data.item.sku }}</div>
            <div class="border-top">{{ data.item.name }}</div>
          </template>
          <template v-slot:cell(returnReason)="data">
            {{ data.item.returnReason }}
          </template>
        </b-table>
      </b-card>
    </div>

    <aside class="col-12 col-sm-3">
      <b-card header="Actions">
        <router-link
          target="_blank"
          v-if="itemForm.id && has('pm.customerBilling.billingEntries')"
          :to="{
            name: 'Billing.CustomerBillingLedger.Index',
            query: { orderReturnId: itemForm.id }
          }"
          class="btn btn-block btn-outline-danger"
        >
          Client Billing Ledger
        </router-link>
        <router-link
          :disabled="savingItem"
          class="mt-2 btn btn-block btn-outline-danger"
          :to="{ name: 'Storefronts.OrderReturns.Index' }"
        >
          <span v-if="hasBeenShipped">Back To Orders</span>
          <span v-else>Cancel</span>
        </router-link>
      </b-card>
    </aside>
  </b-row>
</template>

<script>
import { crudMixinGen, myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
export default {
  mixins: [crudMixinGen('returns'), myCommonDataMixin],
  components: {},
  props: {
    orderReturnId: {
      type: String,
      default: '',
      required: false
    }
  },

  data: () => ({
    heading: 'Order Return',
    // subheading: 'Here you create or edit your Scheduled Products.',
    // icon: 'pe-7s-graph text-success',
    readyToLoad: false,

    RemoveItem(item) {
      // if (item.id) {
      //   // should call API to remove it
      //   this.$http
      //     .delete('/api/common/manualShipmentItems/' + item.id)
      //     .then(respa => {})
      //     .catch(err => {});
      // }
      this.itemForm.items = this.itemForm.items.filter(a => a.id != item.id);
    },

    fieldsItems: [
      { key: 'sku', label: 'Item', sortable: false },
      //{ key: 'rma', label: 'RMA', sortable: false },
      { key: 'quantity', label: 'Qty Shipped', sortable: false },
      { key: 'quantityToReturn', label: 'Qty Returned', sortable: false },
      { key: 'quantityReceived', label: 'Qty Restocked', sortable: false },
      { key: 'quantityDamaged', label: 'Qty Damaged', sortable: false },
      { key: 'lotNumber', label: 'Lot', sortable: false },
      { key: 'expirationDate', label: 'Exp. Date', sortable: false },
      //{ key: 'actions', label: 'Action', sortable: false }
    ]
  }),

  computed: {
    hasBeenShipped: {
      get() {
        let isTrue = false;
        if (this.itemForm.id) {
          isTrue = true;
        }
        if (this.has('pm.is_end_partner')) {
          isTrue = true;
        }
        return isTrue;
      },
      set(old, val) {}
    }
  },

  watch: {
  },

  mounted() {
    if (this.orderReturnId) {
      this.ResetForm();
      //this.LoadOrderReturn(this.orderReturnId);
      this.LoadOrderReturn();
    } else {
      this.ResetForm();
      this.readyToLoad = true;
    }
  },

  methods: {
    LoadOrderReturn() {
      this.$http
        .get('/api/common/orderReturns/' + this.orderReturnId)
        .then(resp => {
          this.itemForm = resp.data;

          if (this.itemForm.createDateTimeUtc)
            this.itemForm.createdAt = new Date(this.itemForm.createDateTimeUtc)
              .toISOString()
              .split('T')[0];

          if (this.itemForm.receiveDate)
            this.itemForm.receiveDate = new Date(this.itemForm.receiveDate)
              .toISOString()
              .split('T')[0];
          //this.LoadCarriers();
          //this.LoadFromLocations();
        })
        .catch(err => {
          console.error('LoadOrderReturn', err);
        });
    },
  }
};
</script>
