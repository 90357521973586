import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';

//import i18n from '@/libs/i18n';
import router from './router';
import store from './store';
import App from './App.vue';

import TextareaAutosize from 'vue-textarea-autosize'
Vue.use(TextareaAutosize)

import * as TinyCookie from 'tiny-cookie'
import * as Sentry from '@sentry/vue';
//import { BrowserTracing } from '@sentry/tracing';

// Global Components
import './global-components';
// 3rd party plugins
const $http = require('axios');
window.$http = Vue.prototype.$http = $http;
import '@/libs/acl';
import '@/libs/portal-vue';
import '@/libs/clipboard';
import '@/libs/toastification';
import '@/libs/sweet-alerts';
import '@/libs/vue-select';
import '@/libs/tour';

// VueMoment
import moment from 'moment-timezone';
import VueMoment from 'vue-moment';
Vue.use(VueMoment, {
  moment
});

// Axios Mock Adapter

// use whole bs vue
Vue.use(BootstrapVue);

import VeeValidate from 'vee-validate';
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
  errorBagName: 'veeErrors'
});

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
// Global registration
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead);

// Composition API
Vue.use(VueCompositionAPI);
var filter = function(text, length, clamp){
  clamp = clamp || '...';
  var node = document.createElement('span');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);

let dsnKey = 'https://7f5ba7cc37832ace914601e0ee711a5b@us.sentry.io/4506699681759232';
let envSentry = '';
if (window.location.hostname.includes('stagingadmin')) {
  envSentry = 'admin-staging';
} else if (window.location.hostname.includes('staging.')) {
  envSentry = 'brand-staging';
} else if (window.location.hostname.includes('admin.')) {
  envSentry = 'admin';
} else if (window.location.hostname.includes('localhost')) {
  envSentry = 'localhost';
} else {
  envSentry = 'brand';
}

$http.interceptors.response.use(
  (response) => response,
  (error) => {
    if(error && error.response && error.response.status == 401) {
      console.log('401 error', error)
      store.dispatch('account/refreshTokenOrLogout');
     return Promise.reject(error);
    }
  },
);
if(envSentry != 'localhost') {
  $http.interceptors.response.use(
    (response) => response,
    (error) => {
      if(error && error.response && error.response.status != 404 && !(error.response.config.url.includes('refresh-token'))) {
        if(error.response.data && typeof error.response.data === 'string' && !error.response.data.includes('Site Under Construction')) {
          Sentry.withScope(scope => {
            scope.setExtra('Method', error.response.config.method)
            scope.setExtra('Response', error.response.data)
            try {
              //scope.setExtra('ResponseString', JSON.stringify(error.response.data))
            } catch {
            //console.info('error with ResponseString')
            }
            scope.setExtra('URL', error.response.config.url)
            error.name = 'API Error';
            error.message = 'API Call Failed for ' + error.response.config.url;
            Sentry.captureException(error);
          });
        }
      return Promise.reject(error);
      }
    },
  );

  Sentry.init({
    environment: envSentry,
    Vue,
    dsn: dsnKey,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false
      }),
    ],
    logErrors: true,
    trackComponents: true,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.0,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1
  });
}
import { NotificationsHub } from './shared';
Vue.use(NotificationsHub);
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
VueCookies.config('1d');
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css'); // For form-wizard

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');
if(process != undefined && process.env != undefined && process.env.VUE_APP_API_URL) {
  $http.defaults.baseURL = process.env.VUE_APP_API_URL;
  window.$http.defaults.baseURL = process.env.VUE_APP_API_URL;
}
Vue.config.productionTip = false;
window.$cookie = Vue.prototype.$cookie = TinyCookie;
window.mainVue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

import './registerServiceWorker';
